import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { IReview } from 'src/app/model/reviews.model';
import { Expense } from '../model/expense.model';
import { map } from 'rxjs/operators';



@Injectable({ providedIn: 'root' })
export class RwcommentsrvService {

  constructor(private http: HttpClient){ }
  


  saveGuestComment(postData: {_score: string, _comment:  string, _pros: string, _cons: string , _rwcommentsrc: string }){
    console.log(postData);
      this.http.post('https://tradailonline.firebaseio.com/reviews.json',postData)
      .subscribe(responseData => {
        console.log(responseData);
      });
  }

  getReviewComments() {
    return this.http.get<{[key: string]: IReview}>('https://tradailonline.firebaseio.com/reviews.json')
    .pipe(
      map(responseData => {
      const postsArray: IReview[] = [];
      for(const key in responseData){
        if(responseData.hasOwnProperty(key)){
            postsArray.push({... responseData[key], id: key});
        }
      }
      console.log('RwcommentsrvService calling retrive comments ',postsArray.length);
      return postsArray;
    })
    );
  }


  

}
