import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logouth',
  templateUrl: './logouth.component.html',
  styleUrls: ['./logouth.component.scss']
})
export class LogouthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
