import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grapha',
  templateUrl: './grapha.component.html',
  styleUrls: ['./grapha.component.css']
})
export class GraphaComponent implements OnInit {
  public chartType: string = 'line';

  public chartDatasets: Array<any> = [
    { data: [65, 59, 80, 81, 56, 55, 40,80,20,19,20,40], label: 'Bookings for 2018' },
    { data: [28, 48, 40, 19, 86, 27, 90,80,20,19,20,40], label: 'Bookings for 2019' }
  ];

  public chartLabels: Array<any> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'];

  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: 'rgba(200, 99, 132, .7)',
      borderWidth: 2,
    },
    {
      backgroundColor: 'rgba(0, 137, 132, .2)',
      borderColor: 'rgba(0, 10, 130, .7)',
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
  
  constructor() { }

  ngOnInit() {
  }

}
