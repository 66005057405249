import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-defaultdash',
  templateUrl: './defaultdash.component.html',
  styleUrls: ['./defaultdash.component.css']
})
export class DefaultdashComponent implements OnInit {
  compSelected: string = "default"
  
  constructor(private _router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params
    .subscribe(
      (params ) => {
           if (typeof params['id'] != 'undefined') {
            this.compSelected = params['id'];
          }else{
          //  console.log('else id',params['id']);
            this.compSelected = 'default';
          }
          
      }
    )

  }
  
}
