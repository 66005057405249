import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthserviceService } from './services/authservice.service';

@Injectable({
  providedIn: 'root'
})
export class AuthgGuard implements CanActivate {
  
  constructor(private _authService: AuthserviceService,
              private _router: Router
            ){}
  
  
  
  canActivate(): boolean  {
    if(this._authService.loggedIn()){
      return true
    }else{
      console.log('RouteGuardActivated .. go back to home page')
      this._router.navigate([''])
      return false
    }
  
  }
  
}
