import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IExpense, Expense } from '../model/expense.model';
import { ManageexpComponent } from '../admin/dashboard/rightpane/manageexp/manageexp.component';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExpmanagesrvService {


  expURL: string = "https://tradailonline.firebaseio.com/expenses.json";
  delExpURL: string = "https://tradailonline.firebaseio.com/expenses/"
  exp: IExpense;
  allExpenses: IExpense[] = [];

  constructor(private http: HttpClient) { }

  addExpense(_exp: IExpense){
   return this.http.post(this.expURL,_exp);
  }

  setAllExpense(_allExp:IExpense[]){
    this.allExpenses = _allExp;
    console.log('exp recied',this.allExpenses.length)
  }

  getExpense() {
    console.log('ExpmanagesrvService calling expenes  ');
    return this.http.get<{[key: string]: Expense}>(this.expURL)
    .pipe(
      map(responseData => {
      const postsArray: Expense[] = [];
      for(const key in responseData){
        if(responseData.hasOwnProperty(key)){
            postsArray.push({... responseData[key], expid: key});
        }
      }
      return postsArray;
    })
    );
  }

  deleteExpense(expID: string){
    var combineUrl = this.delExpURL + expID + ".json";
    console.log(combineUrl)
    return this.http.delete(combineUrl);
  }

}
