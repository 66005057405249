import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  FOLDER = 'reciepts';
  data = [];
  constructor() { }

  uploadFile(file) {
    const contentType = file.type;
    const bucket = new S3(
          {
              accessKeyId: 'AKIA5KKQQSIU33364LCE',
              secretAccessKey: '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',
              region: 'eu-west-1'
          }
      );
      const params = {
          Bucket: 'tradailonline-reciepts',
          Key: this.FOLDER + '/'+ file.name,
          Body: file,
          ACL: 'public-read',
          ContentType: contentType
      };
      bucket.upload(params, function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              return false;
          }
          console.log('Successfully uploaded file.', data);
          this.data = data;
          return true;
      });
  //for upload progress   
  /*bucket.upload(params).on('httpUploadProgress', function (evt) {
          console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
      }).send(function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
      });*/
  }

  getData() :string[]{
      return this.data;
  }



}

