import { Component, OnInit } from '@angular/core';
import { ExpmanagesrvService } from 'src/app/services/expmanagesrv.service';
import { IExpense, Expense } from 'src/app/model/expense.model';
import { HttpClient,HttpEventType } from '@angular/common/http';
import { UploadService } from 'src/app/services/upload.service'

@Component({
  selector: 'app-addexpense',
  templateUrl: './addexpense.component.html',
  styleUrls: ['./addexpense.component.css']
})
export class AddexpenseComponent implements OnInit {
  
    exp: IExpense = new Expense();
    enableSubmit  = true ;
    selectedFile: File = null;
    uploadUrl = "https://someurl"
    uploadprogress = "";
    expMsg: string = "";
    uploadedImg = "";
  
  constructor(private _expService: ExpmanagesrvService,
              private _httpClient: HttpClient,
              private uploadService: UploadService
              ) { }

  ngOnInit() {
  }
  
  addExp(event: Event){
    this.enableSubmit = false;
    this._expService.addExpense(this.exp).subscribe(
      res =>{
        this.exp = new Expense();
        this.expMsg = "Expense Added to Cloudstore";
        console.log(this.exp);
      }
    );
    
  }
  
  onFileSelected(event){
    this.selectedFile = <File>event.target.files[0];
  }
  onUpload(){

    this.uploadService.uploadFile(this.selectedFile);
    this.uploadedImg = this.uploadService.data['Location'];
    console.log('uploaded image locatin',this.uploadedImg);
    // const fd = new FormData();
    // fd.append('image',this.selectedFile,this.selectedFile.name);
    //   this._httpClient.post(this.uploadUrl,fd ,{
    //     reportProgress: true,
    //     observe: 'events'
    //   })
    //   .subscribe(
    //     event => {
    //       if(event.type === HttpEventType.UploadProgress){
    //           this.uploadprogress = "Uploading .. " + Math.round(event.loaded/ event.total *100 ) + "%";
    //       }else if (event.type === HttpEventType.Response){
    //         console.log(event);
    //       }
          
    //     }
    //   )
  }

}
