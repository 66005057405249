import { Component, OnInit } from '@angular/core';
import { ExpmanagesrvService } from 'src/app/services/expmanagesrv.service';
import { IExpense } from 'src/app/model/expense.model';

@Component({
  selector: 'app-graphb',
  templateUrl: './graphb.component.html',
  styleUrls: ['./graphb.component.css']
})
export class GraphbComponent implements OnInit {

  public chartType: string = 'polarArea';

  allExp: IExpense[] = [];

  public chartDatasets: Array<any> = [
    
  ];

  public chartLabels: Array<any> = ['Grocery', 'Cleaning', 'Oil', 'Broadband', 'Electricity','Repair','TV','Checkout' ];
  chartReady = false; 
  public chartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(219, 0, 0, 0.1)',
        'rgba(101, 50, 47, 1)',
        'rgba(162, 131, 129, 1)',
        'rgba(0, 165, 2, 0.1)',
        'rgba(255, 195, 15, 0.2)',
        'rgba(55, 59, 66, 0.1)',
        'rgba(0, 0, 0, 0.3)'
      ],
      hoverBackgroundColor: [
        'rgba(219, 0, 0, 0.2)',
        'rgba(255, 17, 0, 1)',
        'rgba(210, 194, 193, 1)',
        'rgba(0, 165, 2, 0.2)',
        'rgba(255, 195, 15, 0.3)',
        'rgba(55, 59, 66, 0.1)',
        'rgba(0, 0, 0, 0.4)'
      ],
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }


  constructor(private expSrv: ExpmanagesrvService) { }

  ngOnInit() {
        this.expSrv.getExpense().subscribe(
          resp => {
            this.allExp = resp;
            this.calculateExp();
          }
        );
       
  }
  calculateExp(){
    let grocery: number = 0;
    let cleaning: number = 0;
    let oil: number = 0;
    let broadband: number = 0;
    let electricity: number = 0;
    let repair: number = 0;
    let tv: number = 0;
    let checkout: number = 0;

  console.log('Graph Component Exp Count:',this.allExp.length);
  //'Grocery', 'Cleaning', 'Oil', 'Broadband', 'Electricity','Repair','TV'
        this.allExp.forEach(element => {
        
          switch(element.expcategory){
            case 'grocery' : {  grocery += Number(element.amount);
              break;
            }
            case 'cleaning' : { 
              cleaning = cleaning + Number(element.amount);
              break;
            }
            case 'checkout' : { 
              checkout = checkout + Number(element.amount);
              break;
            }
            case 'oil' : {  oil += Number(element.amount);
              break;
            }
            case 'broadband' : {  broadband += Number(element.amount);
              break;
            }
            case 'electricity' : {  electricity += Number(element.amount);
              break;
            }
            case 'repair' : {  repair += Number(element.amount);
              break;
            }
            case 'tv' : {  tv += Number(element.amount);
              break;
            }
          }//end switch
          
        });
        
        let _data =  { data: [grocery, cleaning, oil, broadband, electricity, repair, tv, checkout], label: '2019 Expenses' }   ;
        
        this.chartDatasets.push(_data);
        console.log('all data set',this.chartDatasets);
        this.chartReady = true;
      }

}
