import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pgnotfound',
  templateUrl: './pgnotfound.component.html',
  styleUrls: ['./pgnotfound.component.css']
})
export class PgnotfoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
