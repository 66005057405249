import { Component, OnInit } from '@angular/core';
import { ExpmanagesrvService } from 'src/app/services/expmanagesrv.service';
import { IExpense } from 'src/app/model/expense.model';
import { RwcommentsrvService } from 'src/app/services/rwcommentsrv.service';
import { IReview } from 'src/app/model/reviews.model';

@Component({
  selector: 'app-viewreport',
  templateUrl: './viewreport.component.html',
  styleUrls: ['./viewreport.component.scss']
})
export class ViewreportComponent implements OnInit {
  

  allExp: IExpense[] = [];
  totExp: number = 0;
  totBookings: number = 0;
  plusRatings: number = 0;
  negativeRatings: number = 0;
  allComments: IReview[] = [];


  constructor(private expSrv: ExpmanagesrvService,
              private rvwComSrv: RwcommentsrvService) { }

  ngOnInit() {
    this.expSrv.getExpense().subscribe(
      resp => {
        this.allExp = resp;
        this.calculateExp();
      }
    );
  
    this.rvwComSrv.getReviewComments().subscribe(
        respComm => {
          this.allComments = respComm;
          this.computeComments();
        }
    )

  }

  calculateExp(){
    this.allExp.forEach(element => {
    this.totExp += Number(element.amount);
      switch(element.expcategory){
        case 'checkout' : {  this.totBookings += 1;
          break;
        }
     }//end switch       
   });
  }

  computeComments(){
    this.allComments.forEach( eachComm => {
       let _score = Number(eachComm.score);
       if(_score >= 6){
        this.plusRatings = this.plusRatings + 1;
       }else{
         this.negativeRatings = this.negativeRatings + 1;
       }
    } //loop score 
    )
  }


}
