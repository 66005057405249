
export interface ICustomReq{
    name: string,
    email: string,
    bookingRef: string,
    fccategory: string,
    contactNum: string,
    checkinDate: string,
    message: string
}
export class CustomReq implements ICustomReq{
    name: string;
    email: string;
    bookingRef: string;
    fccategory: string;
    contactNum: string;
    checkinDate: string;
    message: string;
}