
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes,RouterModule } from '@angular/router';
import { PublicComponent } from './public/public.component';
import { ContactusComponent } from './public/contactus/contactus.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AddreviewComponent } from './public/addreview/addreview.component';


import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PgnotfoundComponent } from './pgnotfound/pgnotfound.component';
import { AdminComponent } from './admin/admin.component';
import { AuthgGuard } from './authg.guard';
import { MDBBootstrapModule,ChartsModule } from 'angular-bootstrap-md';
import { CustomreqComponent } from './public/customreq/customreq.component';

const appRoutes: Routes = [
    { path: '', component: PublicComponent ,pathMatch: 'full' }, 
    { path: 'contactus', component: ContactusComponent }, 
    { path: 'admin', component: AdminComponent }, 
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthgGuard] }, 
    { path: 'dashboard/:id', component: DashboardComponent, canActivate: [AuthgGuard] }, 
    { path: 'addreview' , component: AddreviewComponent },
    { path: 'customreq', component: CustomreqComponent},
    { path: '**', component: PgnotfoundComponent}
  
  ];


@NgModule({
    declarations: [],
    imports: [ CommonModule, RouterModule.forRoot(appRoutes),ChartsModule ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule{

}