import { Component, OnInit } from '@angular/core';
import { Expense } from 'src/app/model/expense.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ExpmanagesrvService } from 'src/app/services/expmanagesrv.service';

@Component({
  selector: 'app-manageexp',
  templateUrl: './manageexp.component.html',
  styleUrls: ['./manageexp.component.scss']
})
export class ManageexpComponent implements OnInit {
  
  
  
  elements: Expense[] = [];

  headElements = ['Id','Exp Desc', 'Amt', 'Date', 'Category'];


  constructor(private  http: HttpClient,
              private _router: Router,
              private expSrv: ExpmanagesrvService ) { }

  ngOnInit() {
    this.elements = this.getExpense();
  }

  deleteExpense(expID){
    this.expSrv.deleteExpense(expID).subscribe(
      resp =>{
        this.ngOnInit();
      }
    )
  }

  getExpense() {
    console.log('calling retrive comments ');
    this.expSrv.getExpense()
    .subscribe(
      posts => {       
        this.elements = posts;
        this.expSrv.setAllExpense(this.elements);
        console.log('suscribe of Expense :',this.elements.length);
        
      }
    );
    console.log('number of reviews :',this.elements.length)
    return this.elements;
  }

}
