import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthserviceService } from '../services/authservice.service';
import { IUser } from '../model/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: IUser;  
  icon  = 'glyphicon-log-in';
  loginLabel = 'Login Now';
   loginUrl = "https://tradailonline.auth.eu-west-1.amazoncognito.com/login?response_type=token&client_id=6ta5o6mnequso9ma8bhrdsaon8&redirect_uri=https://www.tradailonline.com/admin"
  
  constructor(private router: Router,
    private authService: AuthserviceService 
    ) { }

  ngOnInit() {
      this.authService.updateUser()
      .subscribe(
          res => this.update(res),
          err => console.log(err),
      );
  }

  logout(event: Event){
    this.authService.logout();
    this.setIcons();
    this.router.navigate(['']);
  }



  update(data :IUser){
    this.setIcons();
     // this.router.navigateByUrl(this.loginUrl);
  }
  


  setIcons(){
    if(!!localStorage.getItem('authStatus')){
      this.loginLabel = ' SN Fatima'
      this.icon = 'glyphicon-user'
    }else {
      this.icon  = 'glyphicon-log-in';
      this.loginLabel = 'Login Now';
    }
  }
}
