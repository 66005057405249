import { Component, 
          OnInit, 
          Output,
          EventEmitter} 
          from '@angular/core';


@Component({
  selector: 'app-leftpane',
  templateUrl: './leftpane.component.html',
  styleUrls: ['./leftpane.component.css']
})
export class LeftpaneComponent implements OnInit {
  

  constructor() { }

  ngOnInit() {
  }

  onSelect(feature: string){

  console.log('leftpane ts:',feature);
  }

}
