import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IUser, User } from '../model/user.model';
import { Observable } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {
   code: string;
  private _url: string = "/assets/mockUser.json"
  user: IUser= new User();

  constructor(private http: HttpClient) { }
  decodedToken = helper.decodeToken(localStorage.getItem('id_token'));


  updateUser(): Observable<IUser>   {  
    this.user.email = this.decodedToken['email'];
    this.user.family_name = this.decodedToken['family_name'];
    this.user.given_name = this.decodedToken['given_name'];
    this.user.nickname = this.decodedToken['nickname'];
    this.user.phone_number = this.decodedToken['phone_number'];
    this.user.given_name = this.decodedToken['cognito:groups'];
 ///his.http.get<IUser>(this._url);

    return  this.http.get<IUser>(this._url);
  }

  //Dashboard calls this as the success full login comes to /admin
  // recieve /admin#id_token=ab.xy.az&expires_at=lkjlj&onemore=jjgdfkgjd
  parseResponse(): boolean{
      var retFlag:boolean  = false;
      const isExpired = helper.isTokenExpired(localStorage.getItem('id_token'));
      if(isExpired){
        return false;
      }
      this.decodedToken = helper.decodeToken(localStorage.getItem('id_token'));
      
      if(this.validateToken()){
        console.log('********************** Family Name :',this.decodedToken['family_name']) ;
        this.updateUser();
          return true; 
      }else{
          return false;
      }
   
  }

  validateToken(){
    return true;
  }

  loggedIn(){
    return !!localStorage.getItem('id_token');
  }

  logout(){
    localStorage.clear();
  }

}
