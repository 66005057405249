import { Component, OnInit } from '@angular/core';
import { RwcommentsrvService } from "../services/rwcommentsrv.service";

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css'],
  providers: [RwcommentsrvService]
})
export class PublicComponent implements OnInit {

  constructor(private commentsSrv: RwcommentsrvService ) { }
  weatherUrl = "https://api.openweathermap.org/data/2.5/weather?q=Letterkenny,ie&appid=cafe16c411bd3bd5781905c8aa8280c4";
  ngOnInit() {
  }

}
