
export interface IExpense{
    expid: string,
    desc: string,
    amount: number,
    expdate: number,
    expcategory: string,
    expbillurl: string
}
export class Expense implements IExpense{
    expid: string;
    desc: string;
    amount: number;
    expdate: number;
    expcategory: string;
    expbillurl: string;
}