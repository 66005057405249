import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { AdminComponent } from './admin/admin.component';

import { LeftpaneComponent } from './admin/dashboard/leftpane/leftpane.component';
import { RightpaneComponent } from './admin/dashboard/rightpane/rightpane.component';
import { AddexpenseComponent } from './admin/addexpense/addexpense.component';
import { HousekeepingComponent } from './admin/dashboard/housekeeping/housekeeping.component';

import { FormsModule } from '@angular/forms';
import { AddressComponent } from './public/address/address.component';



import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ContactusComponent } from './public/contactus/contactus.component';
import { PublicComponent } from './public/public.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AddreviewComponent } from './public/addreview/addreview.component';
import { AppRoutingModule } from './app-routing.module';
import { PgnotfoundComponent } from './pgnotfound/pgnotfound.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { GraphaComponent } from './admin/dashboard/rightpane/grapha/grapha.component';
import { GraphbComponent } from './admin/dashboard/rightpane/graphb/graphb.component';
import { GraphcComponent } from './admin/dashboard/rightpane/graphc/graphc.component';
import { ManagereviewComponent } from './admin/dashboard/rightpane/managereview/managereview.component';
import { Feature4Component } from './admin/dashboard/rightpane/feature4/feature4.component';
import { DefaultdashComponent } from './admin/dashboard/rightpane/defaultdash/defaultdash.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { LogouthComponent } from './logouth/logouth.component';
import { ManageexpComponent } from './admin/dashboard/rightpane/manageexp/manageexp.component';
import { ViewreportComponent } from './admin/dashboard/rightpane/viewreport/viewreport.component';
import { CustomreqComponent } from './public/customreq/customreq.component';  

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PublicComponent,
    AdminComponent,
    DashboardComponent,
    LeftpaneComponent,
    RightpaneComponent,
    AddexpenseComponent,
    HousekeepingComponent,
    ContactusComponent,
    AddressComponent,
    AddreviewComponent,
    PgnotfoundComponent,
    GraphaComponent,
    GraphbComponent,
    GraphcComponent,
    ManagereviewComponent,
    Feature4Component,
    DefaultdashComponent,
    LogouthComponent,
    ManageexpComponent,
    ViewreportComponent,
    CustomreqComponent
    
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
