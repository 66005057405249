import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rightpane',
  templateUrl: './rightpane.component.html',
  styleUrls: ['./rightpane.component.css']
})
export class RightpaneComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    
  }

}
