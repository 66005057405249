import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthserviceService } from '../services/authservice.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  

  constructor(private route: ActivatedRoute,
              private authService: AuthserviceService, 
              private _router: Router
            ) { }

  ngOnInit() {
  //  console.log(this.route)
    this.route.fragment
    .subscribe(
      (params ) => {
      //  console.log("AdminC -- success full authentication recieved code:" , params);
        params.split('&').forEach(part => {
          //console.log(part.split('=')[0],part.split('=')[1])
          localStorage.setItem(part.split('=')[0],part.split('=')[1]);
        })
        if(this.authService.parseResponse()){
          localStorage.setItem('authStatus','true');
          console.log("going to dash board ")
          this._router.navigate(['/dashboard'])
        }else{
          console.log('invalid token');
          this._router.navigate(['']);
        }
        
       
      }
    )
  }

}
