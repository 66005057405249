import { Component, OnInit } from '@angular/core';
import { RwcommentsrvService } from 'src/app/services/rwcommentsrv.service';
import { IReview } from 'src/app/model/reviews.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-managereview',
  templateUrl: './managereview.component.html',
  styleUrls: ['./managereview.component.css']
})
export class ManagereviewComponent implements OnInit {

  loadedPosts: IReview[] =[];

  constructor(private  http: HttpClient,
              private _router: Router) { }

  ngOnInit() {
    this.loadedPosts = this.getFirstFiveComments();
   console.log('retrieved :',this.loadedPosts);
  }

  getFirstFiveComments() {
    console.log('calling retrive comments ');
    this.http.get<{[key: string]: IReview}>('https://beehivetest-992e7.firebaseio.com/reviews.json')
    .pipe(
      map(responseData => {
      const postsArray: IReview[] = [];
      for(const key in responseData){
        if(responseData.hasOwnProperty(key)){
            postsArray.push({... responseData[key], id: key});
        }
      }
      
      return postsArray;
    })
    )
    .subscribe(
      posts => {       
        this.loadedPosts = posts;
        console.log('suscribe of reviews :',this.loadedPosts.length);
        
      }
    );
    console.log('number of reviews :',this.loadedPosts.length)
    return this.loadedPosts;
  }


}
