import { Component, OnInit } from '@angular/core';
import { RwcommentsrvService } from 'src/app/services/rwcommentsrv.service';

@Component({
  selector: 'app-addreview',
  templateUrl: './addreview.component.html',
  styleUrls: ['./addreview.component.css']
  
})
export class AddreviewComponent implements OnInit {
  pannelheadMsg = "Please leave your valuable feedback"
  enableSubmit =  true;
  formValidationMsg = "";

  name?: string;
  country?: string;
  rwdate?: string;
  score: string ="6";
  comment: string;
  pros: string;
  cons: string;
  rwcommentsrc: string = "website";
  postData: any ;


  constructor(private rwcommentSrv: RwcommentsrvService) { }
  
  ngOnInit() {
  }
  
  addreview(event: Event){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var rwdtnow  = mm + '/' + dd + '/' + yyyy;

    this.pannelheadMsg = "Thank you for your feedback. "
    this.enableSubmit = false;
     this.postData =  {score: this.score, comment: this.comment, pros: this.pros, cons: this.cons, recommentsrc: this.rwcommentsrc, name: this.name, country: this.country, date: rwdtnow }

    this.rwcommentSrv.saveGuestComment(this.postData);
    
    this.formValidationMsg = "Thank you for your review ";
  }

  updateScore(event: Event){
    this.score = (<HTMLInputElement>event.target).value;
  }

}
