
export interface IContact{
    name: string,
    email: string,
    message: string
}
export class Contact implements IContact{
    name: string;
    email: string;
    message: string;
}