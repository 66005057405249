import { Component, OnInit } from '@angular/core';
import { IContact, Contact } from 'src/app/model/contact.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  
  formValidationMsg: string = 'Send Message';
  contactFrm: IContact = new Contact();
  enableSubmit = true;


  constructor(private http: HttpClient) { }

  ngOnInit() {
  }
  
  addComment(event: Event){
   var sendurl = 'https://api.tradailonline.com/v1/sendemail';
   var msgData: string = "Name :"+ this.contactFrm.name + "\n" + "Messsage : "+ this.contactFrm.message;
    var genSub = "Contact Us: Guest Name: " + this.contactFrm.name + " message";
   
    var sendJson = {
    "MsgBody": msgData,
    "Subject": genSub
    }
    var ItemJSON = JSON.stringify(sendJson);
    this.enableSubmit = false;
   return this.http.post(sendurl,ItemJSON).subscribe(
     post => {
      this.formValidationMsg = "Message Send ..."
     }
   );
   
  }

}
