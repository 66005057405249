import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICustomReq, CustomReq } from 'src/app/model/customreq.model';

@Component({
  selector: 'app-customreq',
  templateUrl: './customreq.component.html',
  styleUrls: ['./customreq.component.scss']
})
export class CustomreqComponent implements OnInit {


  formValidationMsg: string = 'Send Request';
  enableSubmit = true;
  custReq: ICustomReq = new CustomReq();

  constructor(private http: HttpClient) { }


  ngOnInit() {
  }

  addCustreq(event: Event){
    var sendurl = 'https://api.tradailonline.com/v1/sendemail';
    var msgData: string = this.makeTxtMsg(this.custReq);
     var genSub = "Facility request: Guest Name: " + this.custReq.name + " message";
    

     var sendJson = {
      "MsgBody": msgData,
      "Subject": genSub
      }
      var ItemJSON = JSON.stringify(sendJson);
     this.enableSubmit = false;
    return this.http.post(sendurl,ItemJSON).subscribe(
      post => {
       this.formValidationMsg = "Message Send ..."
      }
    );
    
   }

   makeTxtMsg(_msgData: ICustomReq): string{
    var textMsg = "";
    textMsg += " Guest Name:" + _msgData.name + "\n";
    textMsg += " Guest Email:" + _msgData.email + "\n";
    textMsg += " Guest Contact:" + _msgData.contactNum + "\n";
    textMsg += " Checkin Date:" + _msgData.checkinDate + "\n";
    textMsg += " Booking Reference:" + _msgData.bookingRef + "\n";
    textMsg += " Facilities Request Type:" + _msgData.fccategory + "\n"; 
    textMsg += " Message :" + _msgData.message + "\n";

  return textMsg;
   }

}
