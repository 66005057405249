

export interface IUser{
    email : string,
    nickname: string,
    phone_number? : string,
    given_name: string,
    family_name: string,
    group: string
}

export class User implements IUser {
    email: string;   
    nickname: string;
    phone_number: string;
    given_name: string;
    family_name: string;
    group: string;


}